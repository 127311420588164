<template>
    <v-row>
        <v-col sm="12" cols="12" class="pt-0">
            <v-col sm="12" cols="12" class="pt-0">
                <h2 class="mb-0 font_20">{{ $t('product_categories') }}</h2>
                <v-dialog v-model="dialogM2" max-width="480px">
                </v-dialog>
                <p class="mb-0">
                    {{ $t('categories_desc') }}
                </p>
            </v-col>
            <template>
                <v-simple-table class="attachment_table">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th>{{ $t('abbr') }}</th>
                            <th>{{ $t('color') }}</th>
                            <th>{{ $t('name') }}</th>
                            <th>{{ $t('use') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="pl in categoryList" v-bind:key="pl.id">
                            <td>{{ pl.abbr }}</td>
                            <td>{{ pl.color }}</td>
                            <td>{{ pl.name }}</td>
                            <td>
                                <v-switch
                                    color="primary"
                                    v-model="pl.isUsed"
                                    :label="pl.isUsed ? 'YES' : 'NO'"
                                />
                            </td>
                        </tr>
                        <LoadingMe
                            :isLoading="showLoading"
                            :fullPage="false"
                            :myLoading="true"/>
                        </tbody>
                    </template>
                </v-simple-table>
                
            </template>
            <div class="function_footer">
                <v-btn color="primary" class="float-right white--text text-capitalize"
                       @click="onSaveClose">
                    {{ $t('save') }}
                </v-btn>
            </div>
        </v-col>

    </v-row>
</template>

<script>
import kendo from "@progress/kendo-ui";
const $ = kendo.jQuery;
const categoryHandler = require("@/scripts/categoryHandler");
import CategorySettingModel from "@/scripts/model/pos/CategorySetting"

const posHandler = require("@/scripts/posHandler")

export default {
    components: {
        LoadingMe: () => import(`@/components/Loading`),
    },
    data: () => ({
        src: '',
        dialogM3: false,
        showLoading: true,
        valid: true,
        mItemType: {},
        itemTypes: [],
        c: new CategorySettingModel({}),
        mAccRevenue: '',
        mAccInventory: '',
        accRevenue: [],
        accInventory: [],
        accCostOfGoodSold: [],
        mAccCostOfGoodSold: {},
        tax: [],
        specificTax: [],
        vat: [],
        purchaseTax: [],
        mPurchaseTax: {},
        mSpecificTax: {},
        mOtherTax: {},
        otherTax: [],
        mVAT: {},
        image: '',
        imgFile: '',
        categoryList: [],
        btnEnable: false
    }),
    props: {},
    computed: {},
    watch: {
        dialogM3(val) {
            val || this.close();
        },
    },
    methods: {
        async loadCategory() {
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve("resolved");
                    this.showLoading = true
                    categoryHandler.get().then((res) => {
                        this.showLoading = false
                        // this.categoryList = res
                        if(res.length > 0){
                            let self = this
                            this.categoryList = []
                            $.each(res, function (i,v){
                                self.categoryList.push({
                                    id: v.id,
                                    abbr: v.abbr,
                                    color: v.color,
                                    name: v.name,
                                    isUsed: false
                                })
                            })
                            this.loadSingleCategory()
                        }
                    });
                }, 10)
            });
        },
        async loadSingleCategory() {
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve("resolved");
                    this.showLoading = true
                    posHandler.categorySettingGet().then((res) => {
                        this.showLoading = false
                        const data = res.data.data
                        if (data.length > 0) {
                            this.c = data[0]
                            $.each(this.categoryList, function (i,v){
                                let d = data[0].category.filter((obj) => {return obj.id === v.id && obj.isUsed == true})
                                if(d.length > 0){
                                    v.isUsed = true
                                }
                            })
                        }
                    });
                }, 10)
            });
        },
        async onSaveClose() {
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve("resolved");
                    this.showLoading = true
                    this.c.category = this.categoryList
                    posHandler.categorySettingPost(new CategorySettingModel(this.c))
                        .then((response) => {
                            if (response.data.statusCode === 201) {
                                this.showLoading = false
                                this.$snotify.success('Success')
                            }
                        })
                        .catch((e) => {
                            this.showLoading = false
                            this.$snotify.error('Something went wrong')
                            this.errors.push(e);
                        });
                }, 10)
            });
        },
    },
    mounted: async function () {
        await this.loadCategory()
    },
    created: async function () {
    }
};
</script>

<style scoped>
.acc_group header {
    height: 10px !important;
}

table.acc_group tr td {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    vertical-align: middle;
}

table.acc_group tr td:first-child {
    font-weight: 700;
}

table.acc_group tr td:last-child {
    text-align: center;
}

.v-card__actions .v-btn.v-btn {
    padding: 0 16px;
}

.exchange-table.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th {
    font-family: "Krasar-Bold", serif;
    color: #000 !important;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
    font-size: 15px !important;
}

.exchange-table.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> td {
    color: #000 !important;
    padding: 5px !important;
}

.exchange-table.theme--light.v-data-table
> .v-data-table__wrapper
> table
> tbody
> tr:last-child
td {
    border-bottom: 1px solid #000 !important;
}

.function_footer {
    padding: 15px;
    display: inline-block;
}

p {
    color: rgba(0, 0, 0, 0.87);
}

.actionBtn {
    height: 47.2px !important;
}

.text_tip {
    font-size: 9px;
    line-height: 10px;
}

.v-application .error--text {
    color: #ff5252 !important;
    padding-top: 3px;
    caret-color: #ff5252 !important;
}

@media (max-width: 576px) {
}
</style>
